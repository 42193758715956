<script>
import Vue from 'vue'
export default {
	watch: {
		'$route': {
			handler() {
				Vue.nextTick(this.positionMark)
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		positionMark() {
			let $mark = this.$el.querySelector('.menu__mark')
			let $active = this.$el.querySelector('.menu__link--active')

			if(!$active) return 
			
			let width = $active.clientWidth
			let left = $active.parentElement.offsetLeft + (width/2)
			$mark.style.width = `${(width/2)}px`
			$mark.style.left = `${left}px`
		}
	},

	mounted() {
		this.positionMark()
		window.addEventListener('resize', this.positionMark)
	},

	beforeDestroy() {
		window.addEventListener('resize', this.positionMark)
	}
}
</script>
<template>
	<ul class="menu flex-grow-1">
		<div class="menu__indicator">
			<span class="menu__mark"></span>
		</div>
		<li class="menu__item">
			<router-link :to="{name: 'HomeIndex'}" class="menu__link" active-class="menu__link--active" title="Panshitos" exact>
				Panshitos
			</router-link>
		</li>
		<li class="menu__item">
			<router-link :to="{name: 'Events'}" class="menu__link" active-class="menu__link--active" title="Eventos">
				Eventos
			</router-link>
		</li>
		<li class="menu__item">
			<router-link :to="{name: 'Contact'}" class="menu__link" active-class="menu__link--active" title="Contáctenos">
				Contáctenos
			</router-link>
		</li>
	</ul>
</template>